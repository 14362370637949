import React from 'react';

export const IconYoutube = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={42}
    fill="none"
    {...props}
  >
    <circle opacity={0.2} cx={20.608} cy={21.215} fill="#fff" r={20.392} />
    <path
      d="M32.01 14.936c-.676-1.203-1.41-1.424-2.903-1.508-1.493-.101-5.245-.143-8.496-.143-3.257 0-7.011.042-8.502.141-1.49.086-2.226.306-2.908 1.51-.696 1.2-1.055 3.27-1.055 6.911V21.86c0 3.626.359 5.71 1.055 6.899.682 1.202 1.416 1.42 2.907 1.521 1.492.088 5.246.14 8.503.14 3.25 0 7.003-.052 8.497-.138 1.494-.101 2.228-.32 2.904-1.522.702-1.188 1.057-3.273 1.057-6.899v-.012c0-3.644-.355-5.712-1.059-6.913ZM17.493 26.525v-9.346l7.788 4.673-7.788 4.673Z"
      fill="#fff"
    />
  </svg>
);
