import React from 'react';

export const IconFacebook = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={41}
    height={42}
    fill="none"
    {...props}
  >
    <path
      d="M22.515 17.32v-3.115c0-.86.698-1.558 1.558-1.558h1.557V8.753h-3.115a4.673 4.673 0 0 0-4.673 4.673v3.894h-3.115v3.894h3.115v12.462h4.673V21.214h3.115l1.558-3.894h-4.673Z"
      fill="#fff"
    />
    <circle cx={20.392} cy={21.215} fill="#fff" fillOpacity={0.2} r={20.392} />
  </svg>
);
