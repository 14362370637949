import React from 'react';

 export const CategoyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      fill="none"
      viewBox="0 0 19 18"
    >
      <path
        fill="#27292B"
        d="M18.586 12.281A.75.75 0 0118 13.5H1.5v3.75a.75.75 0 11-1.5 0V1.5A.75.75 0 01.75.75H18a.75.75 0 01.586 1.218L14.46 7.125l4.125 5.156z"
      ></path>
    </svg>
  );
};

