import React from 'react';
type PropsIcon = {
  className?: string;
};
export const LeftArrowBlackIcon = (props: PropsIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="34"
      fill="none"
      viewBox="0 0 22 34"
      {...props}
    >
      <path
        fill="#000"
        d="M21.824 29.33L9.494 17l12.33-12.33L17.154 0l-17 17 17 17 4.67-4.67z"
      ></path>
    </svg>
  );
};
