import React from 'react';

export const IconHamburger = ({ activeMenuMobile, setActiveMenuMobile }) => {
  const toggleMenuMobile = () => {
    setActiveMenuMobile((prevState) => !prevState);
  };

  return (
    <>
      {activeMenuMobile ? (
        <div className="icon-hamburger" onClick={toggleMenuMobile}></div>
      ) : (
        <div className="icon-hamburger active" onClick={toggleMenuMobile}></div>
      )}
    </>
  );
};
