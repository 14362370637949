import { Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { FC, ReactNode } from 'react';

const ButtonPrimary: FC<any> = ({ className, children, loading, ...props }) => {
  return (
    <Button className={classNames('button-primary', className)} {...props}>
      {loading ? (
        <img
          className="loading"
          src="/images/loading-small.gif"
          alt="Loading"
        />
      ) : (
        children
      )}
    </Button>
  );
};

const ButtonLearnMore: FC<{
  text?: string;
  className?: string;
  icon?: ReactNode;
  isIconLeft?: boolean;
  onMouseMove?: any;
  onClick?: any;
}> = ({ text, className, icon, isIconLeft = false, ...props }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="link"
      className={classNames('button-learn-more', className, {
        'icon-left': isIconLeft,
      })}
      icon={
        icon || (
          <img
            src={'/images/arrow-right.svg'}
            alt="Learn more"
            className="icon"
          />
        )
      }
      {...props}
    >
      {text || t('common.buttonLearnMore')}
    </Button>
  );
};

export { ButtonPrimary, ButtonLearnMore };
