import React from 'react';

export const IconTwitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <circle opacity={0.2} cx={20.87} cy={21.215} fill="#fff" r={20.392} />
    <path
      d="M33.331 14.549a10.65 10.65 0 0 1-2.944.806 5.08 5.08 0 0 0 2.248-2.824 10.21 10.21 0 0 1-3.24 1.237 5.108 5.108 0 0 0-8.838 3.494c0 .405.034.794.118 1.165-4.248-.207-8.007-2.243-10.532-5.344-.44.765-.699 1.64-.699 2.582 0 1.77.911 3.339 2.27 4.247a5.045 5.045 0 0 1-2.309-.63v.056a5.133 5.133 0 0 0 4.094 5.02 5.1 5.1 0 0 1-1.34.17 4.52 4.52 0 0 1-.967-.088c.662 2.023 2.54 3.51 4.774 3.56a10.266 10.266 0 0 1-6.335 2.178 9.57 9.57 0 0 1-1.223-.07 14.385 14.385 0 0 0 7.839 2.293c9.402 0 14.542-7.788 14.542-14.54 0-.225-.008-.443-.019-.66a10.194 10.194 0 0 0 2.561-2.652Z"
      fill="#fff"
    />
  </svg>
);
