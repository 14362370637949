import React from 'react';

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#F15622"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.875 18.75a7.875 7.875 0 100-15.75 7.875 7.875 0 000 15.75zM16.443 16.443L21 21"
      ></path>
    </svg>
  );
};
