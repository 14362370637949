import React from 'react';

export const ProgressBigIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1353"
      height="24"
      fill="none"
      viewBox="0 0 1353 24"
    >
      <rect
        width="1329"
        height="6"
        x="12"
        y="9"
        fill="url(#paint0_linear_4126_18242)"
        rx="3"
      ></rect>
      <circle cx="12" cy="12" r="12" fill="#F4773C"></circle>
      <circle cx="12" cy="12" r="9" fill="#fff"></circle>
      <circle cx="12" cy="12" r="5" fill="#F4773C"></circle>
      <circle cx="455" cy="12" r="12" fill="#F69151"></circle>
      <circle cx="455" cy="12" r="9" fill="#fff"></circle>
      <circle cx="455" cy="12" r="5" fill="#F69151"></circle>
      <circle cx="898" cy="12" r="12" fill="#FAAA5D"></circle>
      <circle cx="898" cy="12" r="9" fill="#fff"></circle>
      <circle cx="898" cy="12" r="5" fill="#FAAA5D"></circle>
      <circle cx="1341" cy="12" r="12" fill="#FED078"></circle>
      <circle cx="1341" cy="12" r="9" fill="#fff"></circle>
      <circle cx="1341" cy="12" r="5" fill="#FED078"></circle>
      <defs>
        <linearGradient
          id="paint0_linear_4126_18242"
          x1="12"
          x2="1341.3"
          y1="12"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F15622"></stop>
          <stop offset="1" stopColor="#FED179"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
