import React from 'react';

 export const FireIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      fill="none"
      viewBox="0 0 27 27"
    >
      <path
        fill="url(#paint0_linear_3447_14402)"
        d="M20.954 8.352a.792.792 0 00-1.125.717v1.32a.792.792 0 01-.792.79.795.795 0 01-.785-.663C17.608 6.527 14.677 0 13.5 0c-1.177 0-4.108 6.527-4.75 10.511a.796.796 0 01-.787.669.792.792 0 01-.791-.791v-1.32a.792.792 0 00-1.126-.717c-2.692 1.256-4.411 6.73-4.411 9.947C1.635 23.422 6.514 27 13.5 27c6.986 0 11.865-3.578 11.865-8.701 0-3.217-1.718-8.69-4.411-9.947z"
      ></path>
      <path
        fill="url(#paint1_linear_3447_14402)"
        d="M17.337 16.301a.791.791 0 00-.673-.375H14.91l-.642-2.565a.79.79 0 00-1.474-.162l-3.164 6.328a.79.79 0 00.707 1.145h1.755l.642 2.564a.79.79 0 001.475.162l3.164-6.328a.79.79 0 00-.035-.769z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_3447_14402"
          x1="13.5"
          x2="13.5"
          y1="27"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD5900"></stop>
          <stop offset="1" stopColor="#FFDE00"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_3447_14402"
          x1="13.5"
          x2="13.5"
          y1="23.836"
          y2="12.762"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE59A"></stop>
          <stop offset="1" stopColor="#FFFFD5"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

