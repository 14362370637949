
import React from 'react';
type typeProps = {
  heightIconComplete?: number,
  widthIconComplete?: number
}
export const IconComplete = ({ heightIconComplete, widthIconComplete }: typeProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={widthIconComplete}
      height={heightIconComplete}
      fill="none"
      viewBox="0 0 84 84"
    >
      <g clipPath="url(#clip0_1866_7761)">
        <path
          fill="#EE7952"
          d="M42 84c23.196 0 42-18.804 42-42S65.196 0 42 0 0 18.804 0 42s18.804 42 42 42z"
        ></path>
        <path
          fill="#F05825"
          d="M31.269 60.947l21.596 21.596C70.75 77.773 84 61.476 84 42v-1.192L67.041 25.174 31.27 60.947z"
        ></path>
        <path
          fill="#fff"
          d="M43.06 51.407c1.855 1.855 1.855 5.035 0 6.89l-3.842 3.842c-1.855 1.855-5.035 1.855-6.89 0l-16.827-16.96c-1.854-1.854-1.854-5.034 0-6.889l3.843-3.842c1.855-1.855 5.034-1.855 6.89 0L43.06 51.407z"
        ></path>
        <path
          fill="#fff"
          d="M57.767 22.126c1.855-1.855 5.035-1.855 6.89 0l3.842 3.842c1.855 1.855 1.855 5.035 0 6.89L39.35 61.874c-1.855 1.855-5.035 1.855-6.89 0l-3.842-3.842c-1.855-1.855-1.855-5.035 0-6.89l29.148-29.016z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1866_7761">
          <path fill="#fff" d="M0 0H84V84H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
