import React from 'react';

export const IconLinkedin = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={42}
    height={42}
    fill="none"
    {...props}
  >
    <circle cx={21.131} cy={21.215} fill="#fff" fillOpacity={0.2} r={20.392} />
    <g fill="#fff">
      <path d="M31.782 30.578v-.001h.004V23.46c0-3.481-.75-6.163-4.82-6.163-1.956 0-3.27 1.073-3.805 2.091h-.057v-1.766h-3.859v12.955h4.019v-6.415c0-1.69.32-3.322 2.411-3.322 2.061 0 2.092 1.927 2.092 3.43v6.308h4.015ZM12.702 17.622h4.024v12.956h-4.024V17.622ZM14.712 11.173a2.331 2.331 0 0 0-2.33 2.33c0 1.286 1.044 2.352 2.33 2.352s2.33-1.066 2.33-2.352a2.332 2.332 0 0 0-2.33-2.33Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(12.382 11.173)"
          d="M0 0h19.405v19.405H0z"
        />
      </clipPath>
    </defs>
  </svg>
);
