import React from 'react';

export const ProgressSmallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="351"
      fill="none"
      viewBox="0 0 24 351"
    >
      <rect
        width="334"
        height="6"
        x="15"
        y="7"
        fill="url(#paint0_linear_4126_18280)"
        rx="3"
        transform="rotate(90 15 7)"
      ></rect>
      <circle cx="12" cy="12" r="12" fill="#F25E28"></circle>
      <circle cx="12" cy="12" r="9" fill="#fff"></circle>
      <circle cx="12" cy="12" r="5" fill="#F25E28"></circle>
      <circle cx="12" cy="107" r="12" fill="#F57639"></circle>
      <circle cx="12" cy="107" r="9" fill="#fff"></circle>
      <circle cx="12" cy="107" r="5" fill="#F57639"></circle>
      <circle cx="12" cy="202" r="12" fill="#F99952"></circle>
      <circle cx="12" cy="202" r="9" fill="#fff"></circle>
      <circle cx="12" cy="202" r="5" fill="#F99952"></circle>
      <circle cx="12" cy="339" r="12" fill="#FECB75"></circle>
      <circle cx="12" cy="339" r="9" fill="#fff"></circle>
      <circle cx="12" cy="339" r="5" fill="#FECB75"></circle>
      <defs>
        <linearGradient
          id="paint0_linear_4126_18280"
          x1="15"
          x2="349.076"
          y1="10"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F15622"></stop>
          <stop offset="1" stopColor="#FED179"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
