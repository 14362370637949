import React from 'react';
type PropsIcon = {
  className?: string;
};
export const AddIcon = ({ className }: PropsIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="22"
      fill="none"
      viewBox="0 0 25 22"
      className={className}
    >
      <path
        fill="#060A13"
        d="M.868 13.672V8.536h9.024V.232h5.232v8.304H24.1v5.136h-8.976v8.304H9.892v-8.304H.868z"
      ></path>
    </svg>
  );
};
