import * as React from 'react';

export const IconLinkedinDark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM5.76081 9.93892H8.48027V18.1098H5.76081V9.93892ZM8.65938 7.41135C8.64173 6.6102 8.06883 6 7.13852 6C6.2082 6 5.6 6.6102 5.6 7.41135C5.6 8.1959 6.19023 8.82367 7.10321 8.82367H7.12059C8.06883 8.82367 8.65938 8.1959 8.65938 7.41135ZM15.1566 9.74706C16.9461 9.74706 18.2877 10.9151 18.2877 13.4249L18.2876 18.1098H15.5682V13.7384C15.5682 12.6404 15.1747 11.8911 14.1903 11.8911C13.4389 11.8911 12.9914 12.3962 12.7949 12.8841C12.723 13.0589 12.7053 13.3025 12.7053 13.5467V18.11H9.98555C9.98555 18.11 10.0214 10.7059 9.98555 9.93914H12.7053V11.0965C13.0663 10.5401 13.7127 9.74706 15.1566 9.74706Z"
      fill="#27292B"
    />
  </svg>
);
