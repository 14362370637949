import React from 'react';

export const DownArrowSmallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="7"
      fill="none"
      viewBox="0 0 12 7"
    >
      <path
        stroke="#535A6B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.375 1.25L6 5.625 1.625 1.25"
      ></path>
    </svg>
  );
};
