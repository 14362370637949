import React from 'react';

export const IconExit = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"

    >
        <path
            fill="#535A6B"
            d="M12 23.667a11.667 11.667 0 01-8.253-19.92 11.671 11.671 0 1116.506 16.506A11.592 11.592 0 0112 23.667zM2.667 12.2a9.334 9.334 0 100-.201v.2zm6.31 4.466l-1.644-1.645L10.355 12 7.333 8.978l1.645-1.645L12 10.355l3.022-3.022 1.645 1.645L13.645 12l3.022 3.022-1.644 1.645L12 13.645l-3.022 3.022z"
        ></path>
    </svg>
);
