import * as React from 'react';

const IconUpload = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      d="M27.314 4.67A15.809 15.809 0 0 1 32 15.941c0 6.284-3.722 12-9.483 14.563A1.002 1.002 0 0 1 21.196 30a.995.995 0 0 1 .505-1.316C26.743 26.443 30 21.441 30 15.942c0-7.692-6.28-13.95-14-13.95S2 8.25 2 15.943c0 5.498 3.257 10.5 8.299 12.743a.995.995 0 0 1-.409 1.907 1 1 0 0 1-.407-.087C3.723 27.942 0 22.225 0 15.942 0 11.684 1.664 7.68 4.686 4.669A15.924 15.924 0 0 1 16 0c4.274 0 8.292 1.658 11.314 4.67Zm-7.022 10.096-3.585-3.572a1.003 1.003 0 0 0-1.414 0l-3.585 3.572a.994.994 0 0 0 0 1.409c.39.39 1.024.39 1.414 0L15 14.305v16.583c0 .55.448.996 1 .996s1-.446 1-.996V14.304l1.878 1.871a.998.998 0 0 0 1.414 0 .994.994 0 0 0 0-1.409Z"
      fill="#F15622"
    />
  </svg>
);

export default IconUpload;
